function Filter({ data, updateFilter }) {

  return (
    data.map((elem, key) => (
      <div key={key} className="pr-4">
        <p className=' text-md font-bold'>{elem.heading}</p>
        {elem.headingOptions.map((headingOption, hkey) => (
          <div key={hkey} className="flex gap-2">
            <input
              onChange={()=> updateFilter(elem.name, headingOption.value)}
              className="scale-[1.75] bg-red-500"
              type="checkbox"
            />
            <p>{headingOption.name}</p>
          </div>
        ))}
      </div>
    ))
  );
}

export default Filter;
