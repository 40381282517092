import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { BallTriangle } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'


const ApplyForm = () => {

    const [isLoading, setIsLoading] = useState(false)
    const collegeName = useLocation().pathname.replace("/apply/", "")
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        Email: "",
        Mobile: "",
        Gender: "",
        Location: "",
        Full_Name: "",
        Board_10th: "",
        Board_12th: "",
        Percentage_10th: "",
        percentage_12th: "",
        School_Name_10th: "",
        School_Name_12th: "",
        Passing_Year_12th: "",
        Passing_Year_10th: "",
        Current_Qualification: "",
        College_Name: collegeName,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true)

        fetch("http://localhost:3001/apply", {
            method: 'POST',
            headers: {
                "Content-Type": "Application/json",
            },
            body: JSON.stringify(formData)
        }
        ).then(res => res.json()).then((form) => {

            setIsLoading(false)
            if (form.code === 0 || form.code === 400) {
                toast.error("Failed please try again later")
            }

            else {
                toast.success("You have been applied");
                setTimeout(() => {
                    navigate("/")
                }, 1000);
            }
        }
        ).catch(e => toast.error(e))
    };



    return (
        <div className="py-20 shadow-lg">
            <Toaster />
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                        Apply Now
                    </h2>

                </div>
                <form onSubmit={handleSubmit} className="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2">

                    <div>
                        <label
                            htmlFor="first-name"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Select your gender*
                        </label>
                        <select name='Gender' onChange={handleChange} value={formData.Gender} className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled>
                                Select your gender
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label

                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Current Qualification*
                        </label>
                        <select name='Current_Qualification' onChange={handleChange} value={formData.Current_Qualification} className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled> Select Your Current Qualification</option>
                            <option value={"Currently Persuing 11th Standard"}>Currently Persuing 11th Standard</option>
                            <option value={"Currently Persuing 12th Standard"}>Currently Persuing 12th Standard </option>
                            <option value={"Drop OUt - Year Off"}>Droupcout - Year Off</option>
                            <option value={"Persuing Graduation"}>Currently Persuing Graduation</option>

                        </select>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label
                            htmlFor="first-name"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            10th Standard Details*
                        </label>
                        <select
                            name='Board_10th'
                            onChange={handleChange}
                            value={formData.Passing_Year_10th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled>
                                Select 10th Board
                            </option>
                            <option value="cbse">CBSE</option>
                            <option value="icse">ICSE</option>
                            <option value="state">State Board</option>
                        </select>
                        <select name='Passing_Year_10th'
                            onChange={handleChange}
                            value={formData.Passing_Year_10th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled>
                                Select 10th Passing Year
                            </option>

                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                            <option value={2019}>2019</option>
                            <option value={2018}>2018</option>
                            <option value={2017}>2017</option>
                            <option value={2016}>2016</option>
                            <option value={2015}>2015</option>

                        </select>
                        <input
                            name='Percentage_10th'
                            onChange={handleChange}
                            value={formData.Percentage_10th}

                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                            placeholder={`Enter 10th Percentage`}
                            type='number'
                        />
                        <input
                            name='School_Name_10th'
                            onChange={handleChange}
                            value={formData.School_Name_10th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                            placeholder={`Enter 10th school name`}
                        />
                    </div>


                    <div className='flex flex-col gap-2'>
                        <label
                            htmlFor="first-name"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            12th Standard Details*
                        </label>
                        <select
                            name='Board_12th'
                            onChange={handleChange}
                            value={formData.Passing_Year_12th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled>
                                Select 10th Board
                            </option>
                            <option value="cbse">CBSE</option>
                            <option value="icse">ICSE</option>
                            <option value="state">State Board</option>
                        </select>
                        <select name='Passing_Year_12th'
                            onChange={handleChange}
                            value={formData.Passing_Year_12th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring">
                            <option value={""} selected disabled>
                                Select 12th Passing Year
                            </option>

                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                            <option value={2019}>2019</option>
                            <option value={2018}>2018</option>
                            <option value={2017}>2017</option>
                            <option value={2016}>2016</option>
                            <option value={2015}>2015</option>

                        </select>
                        <input
                            name='percentage_12th'
                            onChange={handleChange}
                            value={formData.percentage_12th}

                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                            placeholder={`Enter 12th Percentage`}
                            type='number'
                        />
                        <input
                            name='School_Name_12th'
                            onChange={handleChange}
                            value={formData.School_Name_12th}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                            placeholder={`Enter 12th school name`}
                        />
                    </div>

                    <div>
                        <label

                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Full Name*
                        </label>
                        <input
                            name="Full_Name"
                            value={formData.Full_Name}
                            onChange={handleChange}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                        />
                    </div>

                    <div className="">
                        <label
                            htmlFor="email"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Email*
                        </label>
                        <input
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                        />
                    </div>
                    <div className="">
                        <label
                            htmlFor="number"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Number*
                        </label>
                        <input

                            name="Mobile"
                            value={formData.Mobile}
                            onChange={handleChange}

                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"
                            type='number'
                        />
                    </div>
                    <div className="">
                        <label
                            htmlFor="location"
                            className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                        >
                            Location*
                        </label>
                        <input
                            name="Location"
                            value={formData.Location}
                            onChange={handleChange}
                            className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-primary transition duration-100 focus:ring"

                        />
                    </div>

                    <div className="flex items-center justify-between sm:col-span-2">

                        <button
                            type="submit"
                            className='p-2 w-3/4 grid place-items-center max-w-[220px] mx-auto rounded-full font-medium text-white transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 '>
                            <span className={`${isLoading ? "hidden" : "block"}`}>Send</span>
                            <BallTriangle
                                height={25}
                                width={25}
                                radius={5}
                                color="white"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={isLoading}
                            />
                        </button>

                        <span className="text-sm text-gray-500">*Required</span>
                    </div>
                    <p className="text-xs text-gray-400">
                        By signing up to our newsletter you agree to our{" "}
                        <a
                            href="#"
                            className="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </form>
                {/* form - end */}
            </div>
        </div>

    )
}

export default ApplyForm