import config from '../../utitls/index.json'
import studying from "../../images/studying.png"
import { Link } from 'react-router-dom';


const MainHero = () => {
  const { mainHero } = config;
  return (
    <main className=" max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col justify-between lg:flex-row mt-20">
      <div className="sm:text-center lg:text-left flex flex-col justify-center mb-5">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl">
          <span className="">{mainHero.title}</span>{' '}
          <span className={`block text-primary`}>
            {mainHero.subtitle}
          </span>
        </h1>
        <p className="mt-3 text-sm text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {mainHero.description}
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="flex justify-center">
            <Link onClick={()=> document.title = "Check | College Predictor"} to={'/check'}
              className='p-2 px-10 rounded-full font-medium text-white transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 '
            >
              {mainHero.primaryAction.text}
            </Link>

          </div>
          <div className="mt-3 flex justify-center sm:mt-0 sm:ml-3">
            <a
              href={mainHero.secondaryAction.href}
              className='p-2 px-12 rounded-full border border-gray-800 hover:border-transparent font-medium text-black transition-all active:scale-90 bg-transparent hover:bg-gradient-to-r  hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 '
            >
              {mainHero.secondaryAction.text}
            </a>
          </div>
        </div>
      </div>
      <div className=' hidden xl:block w-[40vw]'>
        <img className=' mb-10' src={studying}/>
      </div> 
      </main>
  );
};

export default MainHero;
