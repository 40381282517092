import Helpercomponent from "../ReusableComponents/Helpercomponent"
import config from "../../utitls/index.json"
import { useEffect, useState } from "react"


const CollegeReviews = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [collegeReviewData, setCollegeReviewData] = useState(false)

  const { mainTitle, mainDesc, tag } = config.pageTitles.collegeReview


  useEffect(() => {
    setIsLoading(true)
    fetch("http://localhost:3001/collegereview", {
      method: "POST",
      body: {
        id: false
      }
    })
      .then(res => res.json())
      .then((data) => {
        setCollegeReviewData(data);
      }).catch(err => console.log('')).finally(() =>
        setIsLoading(false))
  }, []);

  return (

    <>
      <Helpercomponent isLoading={isLoading} metaData={[mainTitle, mainDesc, tag]} data={collegeReviewData} />
    </>)
}

export default CollegeReviews