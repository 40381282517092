import CourseDetails from "./CourseDetails";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-hot-toast";

const CoursePage = () => {
  const [courseData, setCourseData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const courseID = useLocation().pathname.replace("/courses/", "")

  const id = {
    id: courseID,
  };

  const handleCourseDetails = () => {
    setIsLoading(true)
    fetch("http://localhost:3001/course", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(id),
    }).then(data => data.json()).then(res => setCourseData(res)).finally(() => setIsLoading(false)).catch(e => toast.error(e))
  }

  useEffect(() => {
    handleCourseDetails();
  }, [])


  return (<>


    {
      isLoading ? <div className="grid place-items-center h-screen"><BallTriangle
        height={37}
        width={37}
        radius={5}
        color="#ec4755"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
      /> </div> :
        (courseData && (<div className="pb-16">
          <div className="bg-white pt-14 sm:pt-20">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
              <div className="rounded-lg px-4 py-6 md:py-8 lg:py-12">
                <p className="mb-2 text-center font-semibold text-primary md:mb-3 lg:text-lg">
                  Name
                </p>
                <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl"> {courseData.documents[0].name}
                </h2>
                <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                  {courseData.documents[0].shortDescription}</p>
              </div>
            </div>
          </div>
          <div className="shadow-sm rounded-md p-5 shadow-slate-400 ">
            <div className="items-center px-3 border-b-2 border-gray-300">
              <img className="w-full h-full rounded-md" src={courseData.documents[0].picture} />
            </div>

            <CourseDetails heading="Overview" data={courseData.documents[0].overview} />
            <CourseDetails heading="Objectives" data={courseData.documents[0].objectives} />
            <CourseDetails heading="Benifits" data={courseData.documents[0].benifits} />
            <CourseDetails heading="Curriculum" data={courseData.documents[0].Curriculum} />
            <CourseDetails heading="Who is this course for" data={courseData.documents[0].witcf} />
            <CourseDetails heading="How To Inroll" data={courseData.documents[0].HowToInroll} />
            <div className="w-full flex items-center justify-center">

              <button className="p-3 mt-10 w-full max-w-xs px-10 rounded-full font-bold text-white  transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 ">
                Buy Now{" "} {courseData.documents[0].price}
              </button>
            </div>
          </div>
        </div>
        ))}</>);
};

export default CoursePage;










{/* <div className="px-3 mt-4">
          <p className="font-bold text-md text-gray-900">Overview</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div>
        <div className="px-3 mt-4 ">
          <p className="font-bold text-md text-gray-900">Prequarment</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div>
        <div className="px-3 mt-4">
          <p className="font-bold text-md text-gray-900">Who should get this</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div>
        <div className="px-3 mt-4">
          <p className="font-bold text-md text-gray-900">System requerment</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div>
        <div className="px-3 mt-4">
          <p className="font-bold text-md text-gray-900">More Informatin</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div>
        <div className="px-3 mt-4">
          <p className="font-bold text-md text-gray-900">Feedback</p>
          <p className="text-base lg:text-md">I studied computer science and made a startup too. The quality of teaching is good. The curriculum is always updated and the recent developments in the field are being included in the available data. Yes it does make us job ready</p>
        </div> */}