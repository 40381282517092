import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";

const Login = ({ isLoggedIn, setIsLoggedIn, setProfileInfo }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  if (isLoggedIn) {
    navigate("/")
  }

  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  });



  const handleInputChange = (event) => {
    const { name, value } = event.target;


    setLoginDetails((prevData) => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loginDetails.email.length < 1 || loginDetails.password.length < 1) {
      return toast.error("Please enter all details")
    }
    try {

      setIsLoading(true)
      const res = await fetch('http://localhost:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginDetails)
      })
      const data = await res.json()

      if (data.error) {
        toast.error(data.error)
        return setIsLoading(false)
      }

      if (data.status === true) {
        setProfileInfo(data)
        toast.success("Login successful... Redirecting")
        setTimeout(() => {
          setIsLoggedIn(true)
        }, 1000);
      }

      setIsLoading(false)
    } catch (error) {
      toast.error("Please try again later")
    }
  }


  return (

    <div className="bg-white h-screen flex items-center justify-center ">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8 mb-5">
        <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">
          Login
        </h2>
        <form className="mx-auto  max-w-lg rounded-lg border">
          <div className="flex flex-col gap-4 p-8 md:p-10">
            <div>
              <label
                htmlFor="email"
                className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
              >
                Email
              </label>
              <input
                placeholder="Email"
                name="email"
                value={loginDetails.email}
                onChange={(e) => handleInputChange(e)}
                className="w-full rounded-full border-2 outline-none bg-gray-50 px-3 py-2 text-gray-800 transition duration-100 focus:border-primary"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
              >
                Password
              </label>
              <input
                placeholder="Password"
                name="password"
                value={loginDetails.password}
                onChange={(e) => handleInputChange(e)}
                className="w-full rounded-full border-2 bg-gray-50 px-3 py-2 text-gray-800 outline-none transition duration-100 focus:border-primary"
              />
            </div>
            <button onClick={handleSubmit} className="mt-2 rounded-full px-8 py-2 text-center text-sm font-semibold grid place-content-center text-white outline-none ring-gray-300 transition duration-100 md:text-base active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 ">
              <p className={`${isLoading ? "hidden" : "block"}`}>Login</p>
              <BallTriangle
                height={25}
                width={25}
                radius={5}
                color="white"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={isLoading}
              />
            </button>
            
          </div>
          <div className="flex items-center justify-center bg-gray-100 p-4">
            <p className="text-center text-md text-gray-500">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="text-primary transition duration-100 hover:text-primarydark active:text-primarydark"
              >
                Register
              </Link>
            </p>
          </div>
        </form>
      </div>
      <Toaster />
    </div>

  )
}

export default Login