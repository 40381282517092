
import { Client, ID, Query, Storage } from "appwrite";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";

const Register = ({ isLoggedIn, setIsLoggedIn, setProfileInfo }) => {

  const navigate = useNavigate();

  if (isLoggedIn) {
    navigate("/")
  }

  const [avatar, setavatar] = useState(null)
  const [registerResponse, setRegisterResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [signupDetails, setSignupDetails] = useState({
    name: '',
    email: '',
    password: '',
    work: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignupDetails((prevData) => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (signupDetails.email.length < 1 || signupDetails.password.length < 1 || signupDetails.name < 0 || avatar === null) {
        return toast.error("Please enter all details")
      }
      setIsLoading(true)

      const client = new Client();
      const storage = new Storage(client);

      client
        .setEndpoint(process.env.REACT_APP_ENDPOINT)
        .setProject(process.env.REACT_APP_PROJECT_ID) 

      const promise = storage.createFile(process.env.REACT_APP_BUCKET_ID, ID.unique(), avatar);

      promise.then(function (response) {
        const { $id: picture } = response
        signupDetails.picture = picture
        axios.post("http://localhost:3001/signup", signupDetails).then((data) => {

          if (data.data.status === false) {
            toast.error("Email already exists")
            return setIsLoading(false)

          }
          setProfileInfo(data.data)
          setRegisterResponse(data)
          toast.success("Account has been created... Redirecting")
          setTimeout(() => {
            navigate("/")
            setIsLoggedIn(true)
          }, 1000);
        })
      }, function (error) {
        toast.error(error.message)
      })
    } catch (error) {
      toast.error("Please try again later")
    }
    setIsLoggedIn(false)
  }


  const handleFileChange = async (e) => {
    setavatar(e.target.files[0])
  }

  return (
    <>
      <div className=" pb pt-28 sm:pb-8 lg:pb-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">

          <Toaster />
          <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">
            Register
          </h2>
          <form className="mx-auto max-w-lg rounded-lg border overflow-hidden">
            <div className="flex flex-col gap-4 p-4 md:p-8">
              <div>
                <label
                  htmlFor="name"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Name
                </label>
                <input
                  placeholder="Enter your name"
                  name="name"
                  value={signupDetails.name}
                  onChange={(e) => handleInputChange(e)}
                  className="w-full rounded-full border-2 outline-none bg-gray-50 px-3 py-2 text-gray-800 transition duration-100 focus:border-primary"
                />
              </div>


              <div>
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  work
                </label>
                <input
                  placeholder="What do you do"
                  name="work"
                  value={signupDetails.work}
                  onChange={(e) => handleInputChange(e)}
                  className="w-full rounded-full border-2 outline-none bg-gray-50 px-3 py-2 text-gray-800 transition duration-100 focus:border-primary"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Email
                </label>
                <input
                  placeholder="Enter your email"
                  name="email"
                  value={signupDetails.email}
                  onChange={(e) => handleInputChange(e)}
                  className="w-full rounded-full border-2 outline-none bg-gray-50 px-3 py-2 text-gray-800 transition duration-100 focus:border-primary"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Password
                </label>
                <input
                  placeholder="Enter Password"
                  name="password"
                  value={signupDetails.password}
                  onChange={(e) => handleInputChange(e)}
                  className="w-full rounded-full border-2 bg-gray-50 px-3 py-2 text-gray-800 outline-none transition duration-100 focus:border-primary"
                />
              </div>




              <div className="flex items-center space-x-5">

                <label className="cursor-pointer block">
                  <span className="sr-only cursor-pointer">Choose profile photo</span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="block w-full text-sm cursor-pointer text-slate-500
  file:mr-4 file:py-2 file:px-4
  file:rounded-full file:border-0
  file:text-sm file:font-semibold
  file:bg-pink-50 file:text-primary
  hover:file:bg-pink-100 
"
                  />
                </label>
              </div>



              <button onClick={handleSubmit} className="grid place-items-center mt-2 rounded-full px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 md:text-base active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 ">
                <p className={`${isLoading ? "hidden" : "block"}`}>Signup</p>
                <BallTriangle
                  height={25}
                  width={25}
                  radius={5}
                  color="white"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={isLoading}
                />
              </button>



            </div>
            <div className="flex items-center justify-center bg-gray-100 p-4">
              <p className="text-center text-md text-gray-500">
                have an account?{" "}
                <Link
                  to="/login"
                  className="text-primary transition duration-100 hover:text-primarydark active:text-primarydark"
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Register