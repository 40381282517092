import MainHero from './MainHero'
import Product from './Product'
import Features from './Features'
import Stats from './Stats'
import Testimonials from './Testimonials'
import Heading from './Heading'
import Services from './Services'

const Home = () => {
      
    return (
            <div className='bg-white' >
                <MainHero />
                <Product />
                <Stats />
                <Services />
                <Features />
                <Heading />
                <Testimonials />
            </div >
        )
    }

    export default Home