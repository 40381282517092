const tag = "Engineering"

const courseData = [
  [false],
  ["BE/B.Tech", "B.Sc", "B.Arch", "B. Planning"],
  ["MBBS", "B.Sc(Nursing)", "BDS"],
  ["MBA/PGDM", "Executive MBA"],
  ["Aerospace " + tag, "Agricultural" + tag, "Biomedical" + tag, "Biotechnology", "Chemical " + tag, "Civil " + tag, "Computer Science", "Electrical", "Electrical & Electronics", "Electronica & Communication", "Mechanical " + tag],

  ["BE/B.Tech", "B.Sc", "M.Sc", "BCA", "MCA", "BA"],
]

const indianStates = [
  { name: 'Andhra Pradesh', value: 'andhra_pradesh' },
  { name: 'Arunachal Pradesh', value: 'arunachal_pradesh' },
  { name: 'Assam', value: 'assam' },
  { name: 'Bihar', value: 'bihar' },
  { name: 'Chhattisgarh', value: 'chhattisgarh' },
  { name: 'Goa', value: 'goa' },
  { name: 'Gujarat', value: 'gujarat' },
  { name: 'Haryana', value: 'haryana' },
  { name: 'Himachal Pradesh', value: 'himachal_pradesh' },
  { name: 'Jharkhand', value: 'jharkhand' },
  { name: 'Karnataka', value: 'karnataka' },
  { name: 'Kerala', value: 'kerala' },
  { name: 'Madhya Pradesh', value: 'madhya_pradesh' },
  { name: 'Maharashtra', value: 'maharashtra' },
  { name: 'Manipur', value: 'manipur' },
  { name: 'Meghalaya', value: 'meghalaya' },
  { name: 'Mizoram', value: 'mizoram' },
  { name: 'Nagaland', value: 'nagaland' },
  { name: 'Odisha', value: 'odisha' },
  { name: 'Punjab', value: 'punjab' },
  { name: 'Rajasthan', value: 'rajasthan' },
  { name: 'Sikkim', value: 'sikkim' },
  { name: 'Tamil Nadu', value: 'tamil_nadu' },
  { name: 'Telangana', value: 'telangana' },
  { name: 'Tripura', value: 'tripura' },
  { name: 'Uttar Pradesh', value: 'uttar_pradesh' },
  { name: 'Uttarakhand', value: 'uttarakhand' },
  { name: 'West Bengal', value: 'west_bengal' },
  { name: 'Delhi', value: 'delhi' },
  { name: 'Andaman and Nicobar Islands', value: 'andaman_nicobar' },
  { name: 'Chandigarh', value: 'chandigarh' },
  { name: 'Dadra and Nagar Haveli and Daman and Diu', value: 'dnh_dd' },
  { name: 'Lakshadweep', value: 'lakshadweep' },
  { name: 'Puducherry', value: 'puducherry' },
  { name: 'Ladakh', value: 'ladakh' },
];

const filterData = [{
  name: "location",
  search: true,
  heading: "Location",
  headingOptions: indianStates
},
{
  name: "rating",
  heading: "Rating",
  headingOptions: [{name: "2 Star", value: 2}, {name: "3 Star", value: 3}, {name: "4 Star", value: 4}, {name: "5 Star", value: 5},]
},
{
  name: "ownership",
  heading: "Ownership",
  headingOptions: [{name: "Private", value: "Private"}, {name: "Goverment", value: "Goverment"}]
},
{
  name: "examAccepted",
  heading: "Exams Accepted",
  headingOptions: [{name: "JEE", value: "jee"},{name: "NEET", value: "neet"},{name: "CAT", value: "cat"},{name: "CUET", value: "cuet"}, ]
},
]

export default [indianStates, courseData, filterData]