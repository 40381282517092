import { Link } from "react-router-dom"

const MobileOptions = ({ isOpen, setIsOpen }) => {


    return (
        <div className={`${isOpen ? "scale-1" : "scale-0"} flex flex-col items-center justify-center left-0 top-0 h-screen w-screen fixed transition-all duration-500 bg-white lg:hidden z-50`}>
            <ul className="shrivastavG text-sm flex w-5/6 h-3/4 bg-white flex-col items-center justify-center gap-5">

                <p className="font-bold my-3 text-2xl text-gray-800">Menu</p>
                <Link onClick={() => setIsOpen(false)} to="/check" className="border-2 shadow-lg font-bold text-red-600 hover:scale-90 transition-all hover:bg-red-600 hover:text-white hover:border-white border-red-600 w-3/4 text-center px-8 py-3 rounded-full ">College Predictor</Link>
                
                <Link onClick={() => setIsOpen(false)} to="/topcolleges" className="border-2 font-bold text-green-600 hover:scale-90 transition-all hover:bg-green-600 hover:text-white hover:border-white border-green-600 w-3/4 text-center px-8 py-3 rounded-full shadow-lg ">Top Colleges</Link>

                <Link onClick={() => setIsOpen(false)} to="/reviews" className="border-2 font-bold text-blue-600 hover:scale-90 transition-all hover:bg-blue-600 hover:text-white hover:border-white border-blue-600 w-3/4 text-center px-8 py-3 rounded-full shadow-lg ">College Review</Link>

                <Link onClick={() => setIsOpen(false)} to={"/courses"} className="border-2 font-bold text-purple-600 hover:scale-90 transition-all hover:bg-purple-600 hover:text-white hover:border-white border-purple-600 w-3/4 text-center px-8 py-3 rounded-full shadow-lg ">Courses</Link>

                <Link onClick={() => setIsOpen(false)} to="/contact" className="border-2 shadow-lg font-bold text-red-600 hover:scale-90 transition-all hover:bg-red-600 hover:text-white hover:border-white border-red-600 w-3/4 text-center px-8 py-3 rounded-full ">Contact</Link>
                
                <Link onClick={() => setIsOpen(false)} to="/about" className="border-2 font-bold text-green-600 hover:scale-90 transition-all hover:bg-green-600 hover:text-white hover:border-white border-green-600 w-3/4 text-center px-8 py-3 rounded-full shadow-lg ">About</Link>
                
                <Link onClick={() => setIsOpen(false)} to="/team" className="border-2 font-bold text-blue-600 hover:scale-90 transition-all hover:bg-blue-600 hover:text-white hover:border-white border-blue-600 w-3/4 text-center px-8 py-3 rounded-full shadow-lg ">Team</Link>
                
            </ul>
        </div>
    )
}

export default MobileOptions