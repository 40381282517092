import Filter from "./Filter"
import { BiSearch } from "react-icons/bi"
import TopCollegesList from "./TopCollegesList"
import indianStates from "../../utitls/examData"
import { useState, useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";

const TopColleges = () => {
    const [topCollegeData, setTopCollegeData] = useState({ documents: [] })
    const [isLoading, setIsLoading] = useState(false)
    const [btnActive, setbtnActive] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [filterDetails, setFilterDetails] = useState({
        loan: [],
        rating: [],
        location: [],
        ownership: [],
        examAccepted: [],
    })

    const updateFilter = (name, value) => {
        setFilterDetails((prevData) => {
            const updatedArray = prevData[name].includes(value)
                ? prevData[name].filter((item) => item !== value)
                : [...prevData[name], value];
            return {
                ...prevData,
                [name]: updatedArray,
            };
        });
    }


    const handleLoanState = () => {
        if (btnActive === false) {
            updateFilter("loan", "applicable")
            setbtnActive(true)
        }
        else if (btnActive === true) {
            updateFilter("loan", "not applicable")
            setbtnActive(false)
        }

    }

    useEffect(() => {
        console.log(filterDetails);
    }, [filterDetails])


    useEffect(() => {
        setIsLoading(true)
        fetch("http://localhost:3001/topcollege", {
            method: "POST"
        })
            .then(res => res.json())
            .then((data) => {
                setTopCollegeData(data);
            }).catch(err => console.log('')).finally((d) =>
                setIsLoading(false))
    }, []);


    return (
        <div className='  w-full pt-14 md:pt-20  md:flex text-black'>
            <div className='overflow-y-scroll hidden md:block h-screen md:w-[30vw]'>
                <p className=" font-bold text-2xl text-center ">Filters</p>
                <Filter updateFilter={updateFilter} data={indianStates[2]} />
            </div>
            <div className='w-full '>
                {isLoading ? (
                    <div className="grid h-1/2 place-items-center">
                        <BallTriangle
                            height={37}
                            width={37}
                            radius={5}
                            color="#ec4755"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="shadow border-2 rounded-md mt-5 p-5 flex justify-between items-center text-black">
                        <div className="flex border-gray-400 rounded-full px-2 focus:border-red-500 justify-between items-center border-2 gap-2">
                            <input
                                onChange={(e) => setInputValue(e.target.value)}
                                value={inputValue}
                                type="text"
                                placeholder="Search college in list"
                                name="filterSearch"
                                className="bg-transparent p-2"
                            />
                            <BiSearch className="cursor-pointer hover:text-primary" />

                        </div>
                        <button onClick={handleLoanState} className={`${btnActive ? "btn-active" : ""} btn btn-outline rounded-full`}>Loan Applicable</button>

                    </div>
                )}
                <div className=" grid lg:grid-cols-2">
                    {topCollegeData.documents &&
                        topCollegeData.documents.filter((college) => {

                            if (filterDetails.loan.length <= 0 && filterDetails.rating.length <= 0 && filterDetails.location.length <= 0 && filterDetails.ownership.length <= 0 && filterDetails.examAccepted.length <= 0) {
                                return college
                            }

                            let shouldInclude = false;

                            if (filterDetails.loan.includes(college.loan) || filterDetails.location.includes(college.College_Location) || filterDetails.rating.includes(college.Star) || filterDetails.examAccepted.includes(college.Exam) || filterDetails.ownership.includes(college.type)) {
                                shouldInclude = true
                            }

                            if (shouldInclude) {
                                return college
                            }

                        }).filter((college) => {
                            const collegeName = college.College_Name.replace(/\s+/g, '').toLowerCase();
                            const inputValueNormalized = inputValue.replace(/\s+/g, '').toLowerCase();
                            return inputValueNormalized === '' || collegeName.includes(inputValueNormalized);
                        }).map((collegeInfo, key) => (
                            <TopCollegesList collegeInfo={collegeInfo} key={key} />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default TopColleges