import React, { useState } from "react";
import courseData from "../../utitls/examData";
import { useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";

const CheckResult = ({ collegeUpdate }) => {

    console.log(courseData);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [exam, setExam] = useState(null);
    const [formData, setFormData] = useState({
        exam: "",
        course: "",
        rank: "",
        state: "",
        category: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "exam") {
            setExam(e.target.value);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        fetch("http://localhost:3001/predictor", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setIsLoading(false);
                    return toast.error(data.error);
                }
                collegeUpdate(data);
                navigate("/college");
            })
            .catch((error) => {
                toast.error(error);
            });
    };

    return (
        <div className="text-white w-full h-[100dvh] hellow flex justify-center items-center gap-3 ">
            <Toaster />
            <form className="text-black flex flex-col justify-center gap-4 max-w-[600px] w-full rounded-md shadow-2xl shadow-gray-500 backdrop-blur-5xl p-10 md:px-32 md:py-20">
                <h1 className="text-xl font-bold text-center text-gray-900">
                    Enter Details
                </h1>

                <select
                    value={formData.name}
                    name="exam"
                    onChange={handleChange}
                    className="p-3 border border-gray-700 rounded-full bg-white"
                >
                    <option className="p-3" value="" selected disabled>
                        Select Exam
                    </option>
                    <option value={1} className="p-3">
                        JEE 2024 College Predictor
                    </option>
                    <option value={2} className="p-3">
                        NEET 2023 College Predictor
                    </option>
                    <option value={3} className="p-3">
                        CAT 2023 College Predictor
                    </option>
                    <option value={4} className="p-3">
                        GATE 2024 College Predictor
                    </option>
                    <option value={5} className="p-3">
                        CUET 2024 College Predictor
                    </option>
                </select>
                {exam && (
                    <select
                        value={formData.name}
                        onChange={handleChange}
                        name="course"
                        className="p-3 border-gray-700 border rounded-full bg-white"
                    >
                        <option className="p-3" value="" selected disabled>
                            Select course
                        </option>
                        {courseData.name[1][exam].map((data, key) => (
                            <option key={key} value={data} className="p-3">
                                {data}
                            </option>
                        ))}
                    </select>
                )}
                <input
                    value={formData.name}
                    name="rank"
                    onChange={handleChange}
                    className="p-3 border border-gray-700 rounded-full bg-white"
                    type="number"
                    placeholder="Enter your rank"
                />

                <select
                    value={formData.name}
                    onChange={handleChange}
                    className="p-3 border border-gray-700 rounded-full bg-white"
                    name="state"
                >
                    <option className="p-3" value="" selected disabled>
                        Select state
                    </option>
                    {courseData[0].map((state, key) => (
                        <option className="p-3" value={state.value} key={key}>
                            {state.name}
                        </option>
                    ))}
                </select>
                <select
                    name="category"
                    value={formData.name}
                    onChange={handleChange}
                    className="p-3 border border-gray-700 rounded-full bg-white"
                >
                    <option className="p-3" value="" selected disabled>
                        Select Category
                    </option>
                    <option className="p-3">General</option>
                    <option className="p-3">OBC</option>
                    <option className="p-3">SC</option>
                    <option className="p-3">ST</option>
                </select>
                <button
                    onClick={handleSubmit}
                    className="text-center p-3 px-10 rounded-full font-medium text-white active:scale-90 transition-all bg-gradient-to-l hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 flex items-center justify-center"
                >
                    <p className={`${isLoading ? "hidden" : "block"}`}>Check</p>
                    <BallTriangle
                        height={25}
                        width={25}
                        radius={5}
                        color="white"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={isLoading}
                    />
                </button>
            </form>
        </div>
    );
};

export default CheckResult;
