import { BallTriangle } from "react-loader-spinner";
import Posts from "./Posts";
import toast, { Toaster } from "react-hot-toast";

const Helpercomponent = ({ data, isLoading, metaData }) => {


  if (data.code === 0) {
    toast.success("Plese try again latter")
  }

  return (
    <div className="bg-white pt-20 sm:pt-24 pb-6 sm:pb-8 lg:pb-12">

      <Toaster />
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div   className="courseBG mb-10 md:mb-16">
          <h2 className="mb-4 text-center text-2xl font-bold text-white md:mb-6 lg:text-3xl">
            {metaData[0]}
          </h2>
          <p className="mx-auto max-w-screen-md text-center text-white md:text-lg">{metaData[1]}
          </p>
        </div>

        {
          isLoading ? <div className="grid place-items-center"><BallTriangle
            height={37}
            width={37}
            radius={5}
            color="#ec4755"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          /> </div> :

            <div className="grid sm:grid-cols-2 md:grid-cols-3 sm:gap-4 md:gap-5 ">
              {
                data.documents && data.documents.map((item, key) => (
                  <Posts mainData={item} tag={metaData[2]} />
                ))
              }
            </div>
        }

      </div>
    </div>
  )
}

export default Helpercomponent