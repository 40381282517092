import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import basicInfo from "../utitls/index.json";
import { BiSolidUserCircle } from "react-icons/bi";
import { Client, Storage } from "appwrite";

const Header = ({ isLoggedIn, profileInfo }) => {


  console.log(profileInfo);

  const { picture } = profileInfo
  const client = new Client();
  const storage = new Storage(client);

  client
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID)
    var imgURL = ""
  if (isLoggedIn) {
     imgURL = storage.getFilePreview(process.env.REACT_APP_BUCKET_ID, picture)
  }


  const [isHover, setisHover] = useState(false)
  const navigate = useNavigate()
  const [isOtherHover, setisOtherHover] = useState(false)
  const handleMenuHover = (i) => {
    // alert(i)
    setisHover(i)
    setisOtherHover(true)
  }
  const { name } = basicInfo.company
  return (
    <header className={`Header font-extrabold z-50  lg:px-[120px] px-5 sm:px-10 py-2 fixed top-0 left-0 right-0 text-gray-700`}>
      <div className="contain flex items-center justify-between overflow-hidden">
        <Link to={"/"} className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900 active:scale-[0.9] transition-all">
          <img alt="logo" className="rounded-full w-[30px] h-[30px]" src={logo} />
          <span className="ml-3 text-xl hidden md:inline ">{name}</span>
        </Link>

        <nav onMouseLeave={() => setisOtherHover(false)} className={`hidden uppercase font-bold text-sm md:ml-auto md:mr-auto flex-wrap items-center justify-center lg:flex`}>
          <p onClick={() => window.my_modal_3.showModal()} onMouseEnter={() => handleMenuHover(1)} className={`${isHover === 1 ? "hover:blur-none" : ""} ${isOtherHover == true ? "blur-sm" : ""} cursor-pointer hover:text-primary px-3 py-2 hover:scale-[0.9] transition-all `}>
            Services
          </p>


          <dialog id="my_modal_3" className="modal">
            <form method="dialog" className="modal-box bg-white">
              <button className="btn btn-sm btn-circle border-none outline-none bg-white text-black hover:text-white hover:bg-gray-800 active:scale-75 absolute right-2 top-2">✕</button>
              <h3 className=" font-extrabold shrivastavG text-xl text-center mr-1 mb-2">Services</h3>
              <div className="w-full shrivastavG font-extrabold uppercase grid place-items-center gap-3">
                <button onClick={() => navigate("/check")} className=" px-5 py-1.5 duration-300 rounded-full w-1/2 text-center text-blue-600 transition-all hover:bg-blue-100">College Predictor</button>
                <button onClick={() => navigate("/reviews")} className=" px-5 py-1.5 duration-300 rounded-full w-1/2 text-center text-green-600 transition-all hover:bg-green-100" >College Review</button>
                <button onClick={() => navigate("/courses")} className="px-5 py-1.5 duration-300 rounded-full w-1/2 text-center text-red-600 transition-all hover:bg-red-100">Courses</button>
                <button onClick={() => navigate("/topcolleges")} className=" px-5 py-1.5 duration-300 rounded-full w-1/2 text-center text-yellow-600 transition-all hover:bg-yellow-100" >Top Colleges</button>
              </div>
            </form>
          </dialog>

          <Link to={"/contact"} onMouseEnter={() => handleMenuHover(2)} className={`${isHover === 2 ? "hover:blur-none" : ""} ${isOtherHover == true ? "blur-sm" : ""} hover:text-primary px-3 py-2 hover:scale-[0.9] transition-all `}>Contact</Link>
          <Link to={"/about"} onMouseEnter={() => handleMenuHover(3)} className={`${isHover === 3 ? "hover:blur-none" : ""} ${isOtherHover == true ? "blur-sm" : ""} hover:text-primary px-3 py-2 hover:scale-[0.9] transition-all `}>About</Link>
          <Link to="/team" onMouseEnter={() => handleMenuHover(4)} className={`${isHover === 4 ? "hover:blur-none" : ""} ${isOtherHover == true ? "blur-sm" : ""} hover:text-primary px-3 py-2 hover:scale-[0.9] transition-all `}>Team</Link>
        </nav>

        {isLoggedIn ? <Link to="/profile"><img className=" rounded-full w-[30px] h-[30px]" src={imgURL.href}/></Link> :
          <Link to={"/register"} className='p-2 text-sm px-10 rounded-full font-medium text-white lg:block transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 '>Signup</Link>}
      </div>
    </header>
  );
}

export default Header

{/* <BiSolidUserCircle className="cursor-pointer text-2xl md:text-3xl" /> */}