import { useState } from "react";
import About from "./components/About";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home/Home";
import Team from "./components/Team/Team";
import Contact from "./components/Contact";
import Login from "./components/Auth/Login";
import Notfound from "./components/Notfound";
import ScrollToTop from "./utitls/scrollToTop";
import Profile from "./components/Home/Profile";
import Courses from "./components/Course/Courses";
import Register from "./components/Auth/Register";
import CoursePage from "./components/Course/CoursePage";
import ApplyForm from "./components/ApplyForm/ApplyForm";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import College from "./components/CollegePredictor/College";
import TopColleges from "./components/TopColleges/TopColleges";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CheckResult from "./components/CollegePredictor/CheckResult";
import LaunchingSoon from "./components/LauchingSoon/LaunchingSoon";
import Reviewcontent from "./components/CollegeReview/Reviewcontent";
import CollegeReviews from "./components/CollegeReview/CollegeReviews";
import ContactButton from "./components/ContactButton";


function App() {
  const [colleges, setColleges] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileInfo, setProfileInfo] = useState(false)


  return (
    <div className="App h-screen w-full bg-white
    ">
      <div className=" bg-white w-full backdrop-blur-2xl px-2 sm:px-10 lg:px-[120px] ">
        <BrowserRouter>

          <Header profileInfo={profileInfo} isLoggedIn={isLoggedIn} />
          <MobileMenu />
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/check" element={<CheckResult collegeUpdate={setColleges} />} />
            <Route path="/college" element={<College collegesData={colleges} />} />
            <Route path="/reviews" element={<CollegeReviews />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/register" element={<Register setProfileInfo={setProfileInfo} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/login" element={<Login setProfileInfo={setProfileInfo} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/profile" element={<Profile isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} profileInfo={profileInfo} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/topcolleges" element={<TopColleges />} />
            <Route path="/lauchingsoon" element={<LaunchingSoon />} />
            <Route path="/team" element={<Team />} />
            <Route path="/reviews/:collegeId" element={<Reviewcontent />} />
            <Route path="/courses/:courseId" element={<CoursePage />} />
            <Route path="/apply/:collegeId" element={<ApplyForm />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
          <ScrollToTop />
          <ContactButton/>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
