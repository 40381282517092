import { Link } from "react-router-dom"

const Posts = ({ mainData, tag }) => {

    const { name, picture, shortDescription, $id } = mainData

    const auther = false

    return (

        <div className="flex flex-col min-w-[200px] overflow-hidden rounded-lg border">
            <a
                href="#"
                className="group block h-44 overflow-hidden bg-gray-100"
            >
                <img
                    src={picture}
                    loading="lazy"
                    alt="Photo by Minh Pham"
                    className=" inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                />
            </a>
            <div className="flex flex-1 flex-col p-4 sm:p-6">
                <h2 className="mb-2 text-lg font-semibold text-gray-900">
                    <Link
                        href="#"

                    >
                        {name}
                    </Link>
                </h2>
                <p className="mb-8 text-gray-500">
                    {shortDescription}
                </p>
                <div className="mt-auto flex items-end justify-between">                        
                    <Link to={$id} className={`${auther ? "rounded-md px-3 py-1" : "rounded-full w-full  px-4 py-2"} text-white text-center transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 `}>{tag}</Link>
                </div>
            </div>
        </div>

    )
}

export default Posts