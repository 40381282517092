import { useState, useEffect } from "react";
import Helpercomponent from "../ReusableComponents/Helpercomponent"
import config from "../../utitls/index.json"

const Courses = () => {

  const { mainTitle, mainDesc, tag } = config.pageTitles.course
  const [courseData, setCourseData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetch("http://localhost:3001/course", {
      method: "POST",
      body: {
        id: false
      }
    })
      .then(res => res.json())
      .then((data) => {
        setCourseData(data);
      }).catch(err => console.log('')).finally(() =>
        setIsLoading(false))
  }, []);

  return <Helpercomponent isLoading={isLoading} metaData={[mainTitle, mainDesc, tag]} data={courseData} />
}

export default Courses