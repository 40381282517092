import { AiFillStar } from "react-icons/ai"
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

const Reviewcontent = () => {

  const [collegeReviewData, setCollegeReviewData] = useState(false);
  const [uploadedDate, setuploadedDate] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const reviewID = useLocation().pathname.replace("/reviews/", "")

  const id = {
    id: reviewID,
  };


  useEffect(() => {
    if (collegeReviewData) {
      const inputDate = collegeReviewData.$updatedAt
      const date = new Date(inputDate);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      setuploadedDate(formattedDate)
    }
  }, [collegeReviewData])


  const handleCollegeReviewDetails = () => {
    setIsLoading(true)
    fetch("http://localhost:3001/collegereview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(id),
    }).then(data => data.json()).then((res) => {

      var doc = res.documents[0];
      const reviewData = {
        "Star": doc.star,
        $updatedAt: doc.$updatedAt,
        data: [
          { "Academic Programs": doc.AcademicPrograms },
          { "Accreditations and Rankings": doc.AccreditationsandRankings },
          { "Campus Facilities": doc.CampusFacilities },
          { "Diversity and Inclusion": doc.DiversityandInclusion },
          { "Extracurricular Activities": doc.ExtracurricularActivities },
          { "Financial Considerations": doc.FinancialConsiderations },
          { "Internship and Job Opportunities": doc.InternshipandJobOpportunities },
          { "Locationand Campus Environment": doc.LocationandCampusEnvironment },
          { "StudentFeedbackandReviews": doc.StudentFeedbackandReviews },
          { "Student Life": doc.StudentLife },
        ],
        picture: doc.picture,
        shortDescription: doc.shortDescription,
        Conclusion: doc.Conclusion
      }

      setCollegeReviewData(reviewData)

    }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    handleCollegeReviewDetails();
  }, [])


  useEffect(() => {
    console.log(collegeReviewData);
  }, [collegeReviewData])


  return (

    <>
      {isLoading ? (
        <div className="grid place-items-center h-screen">
          <BallTriangle
            height={37}
            width={37}
            radius={5}
            color="#ec4755"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      ) : (
        collegeReviewData && (
          <>
            <div className="bg-white pt-14 sm:pt-20">
              <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="rounded-lg px-4 py-6 md:py-8 lg:py-12">
                  <p className="mb-2 text-center font-semibold text-primary md:mb-3 lg:text-lg">
                    Name
                  </p>
                  <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                    {collegeReviewData.name}
                  </h2>
                  <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                    {collegeReviewData.shortDescription}
                  </p>
                </div>
              </div>
            </div>

            <div className="shadow-sm rounded-md p-5 shadow-slate-400">
              <div className="items-center mt-5 px-3 border-b-2 border-gray-300">
                <img className="w-full h-full rounded-md" src={collegeReviewData.picture} alt="College" />
                <div className="flex justify-between mt-5 items-center pr-2">
                  <div className="flex items-center text-sm gap-2 py-2 px-3 rounded">
                    <div className="flex items-center gap-2">
                      <div className="h-10 w-10 shrink-0 overflow-hidden rounded-full bg-gray-100">
                        <img
                          src="https://images.unsplash.com/photo-1611898872015-0571a9e38375?auto=format&q=75&fit=crop&w=64"
                          loading="lazy"
                          alt="Author"
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div>
                        <span className="font-bold text-gray-900">Admin</span>
                        <span className="block text-sm text-gray-900">{uploadedDate}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    {
                      Array.from({ length: collegeReviewData.Star }).map(() => (<AiFillStar className="text-red-500" />))
                    }
                  </div>
                </div>
              </div>
              {
                collegeReviewData.data.map((data, key) => (

                  <div className="px-3 mt-4">
                    <p className="font-bold text-md text-gray-900">{Object.keys(data)}</p>
                    <p className="text-base text-gray-600 lg:text-md">
                      {data[Object.keys(data)]}
                    </p>
                  </div>)
                )}

              <div className="px-3 mt-4">
                <p className="font-bold text-md text-gray-900">Conclusion</p>
                <p className="text-base text-gray-600 lg:text-md">
                  {collegeReviewData.Conclusion}
                </p>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default Reviewcontent;