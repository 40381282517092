import { Link } from "react-router-dom";

const TopCollegesList = ({ collegeInfo }) => {

    const { Average_Salary, College_Location, picture, College_Name, Exam, Ranked, Star, Tuition_Fees, Description } = collegeInfo
    const collegeStats = [
        ["Course", "BCA"],
        ["Exams", Exam],
        ["Tuition Fees", Tuition_Fees],
        ["Average Salary", Average_Salary],
        ["star", Star],
        ["Ranked", Ranked]
    ]


    return (
        <div className='shadow-xl w-full py-10' >
            <div className=" pl-5 flex items-center gap-3 leading-none py-1">


                {/* COLLEGE LOGO */}
                <img className="w-14 h-14" src={picture} />

                {/* DETAILED INFO */}
                <div className="flex pt-1 flex-col ">
                    <span className="font-semibold text-md">
                        {College_Name}
                    </span>
                    <div>
                        <span></span>
                        <span className="text-sm">{College_Location}</span>
                    </div>
                    <span className="text-sm">
                        {Description}
                    </span>
                </div>
            </div>

            <div className="pl-5 pt-3 flex-1 w-full items-center md:justify-center overflow-hidden flex-wrap  flex gap-3">
                {
                    collegeStats.map((stat, key) => (
                        <div className="hover:shadow-xl cursor-pointer shadow-md rounded-md  h-[80px] w-[40%] flex items-center justify-center flex-col" key={key}>
                            <p className="font-semibold text-center text-sm">{stat[0]}</p>
                            <p className="text-primary text-sm text-center">{stat[1]}</p>
                        </div>

                    ))
                }

            </div>

            <div className=" mt-8 gap-2 flex justify-center text-xs">
                <Link to={"/apply/fhgj"}
                    className='px-5 py-2 rounded-full font-medium text-white transition-all active:scale-90 bg-gradient-to-r hover:from-green-400 hover:to-blue-500 from-pink-500 to-yellow-500 '
                >

                    Apply Now
                </Link>
            
            </div>

        </div >
    )
}

export default TopCollegesList