import TeamMember from "./TeamMember"
import { useState, useEffect } from 'react'
import { BallTriangle } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const Team = () => {
    const [teamDetails, setTeamDetails] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     setIsLoading(true)
    //     fetch("http://localhost:3001/team", {
    //         method: "POST"
    //     })
    //         .then(res => res.json())
    //         .then((data) => {
    //             setTeamDetails(data);
    //         }).catch(err => toast.error("Please try again later")).finally(() => setIsLoading(false))
    // }, []);

    // useEffect(() => {
    //     if (teamDetails.code === 0) {
    //         toast.error("Please try again later")
    //     }
    // }, [teamDetails])



    const getYTDetails = async () => {
        const options = {
            method: 'GET',
            url: 'https://youtube-v31.p.rapidapi.com/channels',
            params: {
                part: 'snippet,statistics',
                id: 'UCBVjMGOIkavEAhyqpxJ73Dw'
            },
            headers: {
                'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
                'X-RapidAPI-Host': 'youtube-v31.p.rapidapi.com'
            }
        };

        try {
            const response = await axios.request(options);
            setTeamDetails(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getYTDetails()
    }, [])


    return (
        <div className="bg-white py-16 lg:pt-24">
            <Toaster />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                        Meet our Team
                    </h2>
                    <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                        This is a section of some simple filler text, also known as placeholder
                        text. It shares some characteristics of a real written text but is
                        random or otherwise generated.
                    </p>
                </div>
                {

                    isLoading ? <div className="grid place-items-center"><BallTriangle
                        height={37}
                        width={37}
                        radius={5}
                        color="#ec4755"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    /> </div> :

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-8">
                            {teamDetails.items &&
                                    <TeamMember memberDetails={teamDetails.items[0]} />
                            }
                        </div>
                }
            </div>
        </div>

    )
}

export default Team