import React from 'react'

const Stats = () => {
    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
                {/* text - start */}
                <div className="mb-8 md:mb-12">
                    <h2 className="mb-4 text-center text-2xl font-extrabold text-gray-700  md:mb-6 lg:text-3xl">
                     Our Service Statistics
                    </h2>
                    <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                        This is a section of some simple filler text, also known as placeholder
                        text. It shares some characteristics of a real written text but is
                        random or otherwise generated.
                    </p>
                </div>
                {/* text - end */}
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-8">
                    {/* stat - start */}
                    <div className="flex flex-col items-center justify-center rounded-lg shadow-md hover:shadow-xl cursor-pointer bg-gray-100 p-4 lg:p-8">
                        <div className="text-xl font-bold text-gray-600 sm:text-2xl md:text-3xl">
                            200
                        </div>
                        <div className="text-sm font-semibold sm:text-base">People</div>
                    </div>
                    {/* stat - end */}
                    {/* stat - start */}
                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 shadow-md hover:shadow-xl cursor-pointer md:p-8">
                        <div className="text-xl font-bold text-gray-600 sm:text-2xl md:text-3xl">
                            500+
                        </div>
                        <div className="text-sm font-semibold sm:text-base">People</div>
                    </div>
                    {/* stat - start */}
                    <div className="flex flex-col shadow-md hover:shadow-xl cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8">
                        <div className="text-xl font-bold text-gray-600 sm:text-2xl md:text-3xl">
                            1000+
                        </div>
                        <div className="text-sm font-semibold sm:text-base">Customers</div>
                    </div>
                    {/* stat - end */}
                    {/* stat - start */}
                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100  shadow-md hover:shadow-xl cursor-pointer p-4 md:p-8">
                        <div className="text-xl font-bold text-gray-600 sm:text-2xl md:text-3xl">
                            A couple
                        </div>
                        <div className="text-sm font-semibold sm:text-base">Coffee breaks</div>
                    </div>
                    {/* stat - end */}
                </div>
            </div>
        </div>

    )
}

export default Stats