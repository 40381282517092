import { Link } from "react-router-dom"

const College = ({ collegesData }) => {
  const { documents, total } = collegesData
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24  mx-auto">
        <p className="text-base sm:text-lg font-extrabold text-gray-900 mb-4">As per your rank {total} colleges are available for you</p>
        <div className="flex flex-wrap -m-4">
          {documents && documents?.map((elem, key) => (
            <div key={key} className=" shadow-2xl shadow-slate-400 lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block  h-48 rounded overflow-hidden">
                <img
                  alt="ecommerce"
                  className="object-cover object-center w-full h-full block"
                  src={elem.picture}
                />
              </a>
              <div className="mt-4">
                <h3 className="text-gray-900 font-bold text-base tracking-widest title-font mb-1">
                  {elem.collegeName}
                </h3>
                <h2 className="text-gray-700 title-font mb-3 text-sm font-medium">
                  {elem.address}
                </h2>
                <Link to={`/apply/${elem.collegeName.toLowerCase().replace(/ /g, '-')}`} className='top-2 text-center cursor-pointer py-2 px-5 rounded-full font-medium text-white focus:scale-90 transition-all bg-gradient-to-l hover:from-green-400 hover:to-blue-500 my-5 from-pink-500 to-yellow-500  w-fullmd:w-3/4 block mx-auto '>Apply Now</Link>
              </div>
            </div>
          ))
          }
        </div>
      </div>
    </section>

  )
}

export default College